









import Vue from "vue";
import { mapGetters } from "vuex";
import StatisticsTable from "./components/StatisticsTable.vue"

export default Vue.extend({
  name: "EliseStatistics",
  
  components: {
    StatisticsTable
  },

  computed: {
    ...mapGetters({
      statistics: "eliseStatistics/getStats",
    })
  },

  mounted() {
    this.$store.dispatch("eliseStatistics/fetch");
  }
})
