











import Vue from "vue"

export default Vue.extend({
  name: "StatisticsTable",

  props: {
    items: { type: Array, default: null },
  },

  data: () => ({
    headers: [
      {
        text: "Tipo",
        value: "type",
        align: "start"
      },
      {
        text: "Clientes",
        value: "clients",
        align: "start"
      },
      {
        text: "Usuários",
        value: "users",
        align: "start"
      },
    ] as Array<Object>
  }),
})
